@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@layer base {
  /* stroke-width: 2 is a browser default and can be overridden with class="stroke-1" etc. */
  svg {
    stroke-width: 2;
  }

  path,
  circle,
  ellipse,
  line,
  polygon,
  polyline,
  rect {
    stroke-width: inherit;
  }
}

body {
  font-family: 'Lato', sans-serif;
  background: rgba(245, 245, 250, 1);
}

table {
  border-spacing: 0;
}

.sortable-column-header:hover .icon-no-sorting {
  display: block;
}

.clickable-row:hover .row-cell {
  background-color: rgba(250, 250, 255, 1);
}

.clickable-row:focus .row-cell {
  background-color: rgba(245, 245, 250, 1);
}
