.ql-container {
  border: none !important;
}

.ql-toolbar {
  border: none !important;
}

.ql-tooltip {
  left: 0 !important;
}
