/* TODO: [KONG] Should be 100% width */
.react-datepicker-wrapper {
  width: auto;
}

.react-datepicker {
  border: 0px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-family: inherit;
  color: #26262e;
}

.react-datepicker__triangle {
  visibility: hidden;
}

.react-datepicker-popper[data-placement^='top'] {
  padding-bottom: 0;
}

.react-datepicker-popper[data-placement^='bottom'] {
  padding-top: 1px;
}

.react-datepicker__header {
  background-color: white;
  border: none;
  padding: 16px;
  padding-bottom: 0;
}

.react-datepicker__navigation-icon {
  top: 15px;
}

.react-datepicker__navigation {
  width: 57px;
  height: 57px;
  align-items: flex-start;
  top: 0px;
}

.react-datepicker-popper {
  z-index: 40;
}

.react-datepicker__navigation:focus .react-datepicker__navigation-icon--next {
  content: url('../../assets/arrow-icons/nextHover.svg');
  background-color: #f7f7ff;
  border-radius: 9999px;
  box-shadow: 0 0 0 calc(2px) white, 0 0 0 calc(2px + 2px) #8e8cee;
}

.react-datepicker__navigation:focus
  .react-datepicker__navigation-icon--previous {
  content: url('../../assets/arrow-icons/previousHover.svg');
  background-color: #f7f7ff;
  border-radius: 9999px;
  box-shadow: 0 0 0 calc(2px) white, 0 0 0 calc(2px + 2px) #8e8cee;
}

.react-datepicker__navigation-icon--next {
  width: 25px;
  height: 25px;
  display: block;
  content: url('../../assets/arrow-icons/next.svg');
  background-size: 30px 30px;
  z-index: 20;
  left: -7px;
}

.react-datepicker__navigation-icon--next:hover {
  content: url('../../assets/arrow-icons/nextHover.svg');
  background-color: #f7f7ff;
  border-radius: 9999px;
}

.react-datepicker__navigation-icon--previous:hover {
  content: url('../../assets/arrow-icons/previousHover.svg');
  background-color: #f7f7ff;
  border-radius: 9999px;
}

.react-datepicker__navigation-icon--next::before {
  border-style: none;
}

.react-datepicker__navigation-icon--previous {
  width: 25px;
  height: 25px;
  display: block;
  content: url('../../assets/arrow-icons/previous.svg');
  background-size: 30px 30px;
  z-index: 20;
  right: -7px;
}

.react-datepicker__navigation-icon--previous::before {
  border-style: none;
}

.react-datepicker__current-month {
  font-size: 16px;
  font-weight: 600;
  color: inherit;
}

.react-datepicker__day-names,
.react-datepicker__day-name {
  color: #b4b4ba;
  margin: 4px;
}

.react-datepicker__day {
  color: inherit;
  border-radius: 9999px;
  margin: 4px;
  background-color: white;
}

.react-datepicker__day--disabled {
  color: #b4b4ba;
}

.react-datepicker__day:hover:not(.react-datepicker__day--disabled) {
  color: inherit;
  border-radius: 9999px;
  background-color: #e6e5ff;
}

.react-datepicker__day:focus:not(.react-datepicker__day--disabled) {
  color: white;
  background-color: #8e8cee;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  color: white;
  background-color: #8e8cee;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__day--in-range {
  background-color: #e6e5ff;
  color: inherit;
}

.react-datepicker__day--outside-month {
  color: #b4b4ba;
  background-color: white;
  cursor: default;
}

.react-datepicker__header__dropdown {
  margin-top: 4px;
}

.react-datepicker__year-read-view--down-arrow {
  width: 20px;
  height: 20px;
  display: block;
  content: url('../../assets/arrow-icons/down.svg');
  background-size: 20px 20px;
  z-index: 20;
  transform: rotate(0);
  border: none;
  position: relative;
  margin-left: -10px;
}

.react-datepicker__year-read-view {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.react-datepicker__year-dropdown {
  background-color: white;
  width: 40%;
  left: 30%;
  border-color: #d2d2d8;
  height: 280px;
}

.react-datepicker__year-option {
  font-size: 14px;
  color: inherit;
  padding: 4px;
}
.react-datepicker__navigation--years-upcoming,
.react-datepicker__navigation--years-previous {
  display: none;
}

.react-datepicker__year-option:hover {
  background-color: #e6e5ff;
}

.react-datepicker__year-option--selected:after {
  color: #8e8cee;
  content: url('../../assets/arrow-icons/check.svg');
  z-index: 20;
  display: block;
  width: 15px;
  height: 15px;
  visibility: visible;
  position: absolute;
  top: 5%;
}

.react-datepicker__year-option--selected {
  visibility: hidden;
}

.react-datepicker__year-select {
  color: #8e8cee;
  font-size: 16px;
  font-weight: bold;

  cursor: pointer;
}

.react-datepicker__year-select:focus-visible {
  outline: 2px solid #8e8cee;
  border-radius: 4px;
}

.react-datepicker__input-container input {
  display: block;
  width: 264px;
  outline: none;
  padding: 9px 12px;
  border-radius: 6px;
  background-color: #f5f5fa;
  font-size: 14px;
  color: inherit;
  line-height: 20px;
}

.react-datepicker__input-container input:focus {
  box-shadow: 0 0 0 1px #8e8cee;
}
